<template>
  <div>
    <a-modal title="选择商品" v-model="visible" width="70%" :footer="null" >
<!--      <a-steps :current="current" style="width: 70%; margin: 0 auto">-->
<!--        <a-step v-for="item in steps" :key="item.title" :title="item.title" :description="item.content" />-->
<!--      </a-steps>-->
<!--      <div class="steps-content">-->

<!--        <div v-if="current === 1">-->
<!--          <a-table :data-source="dataList" :pagination="page" bordered @change="changeTable" :row-selection="{ selectedRowKeys: policyKeysList, onChange: onSelectedChange }">-->
<!--            <a-table-column title="商品名称" data-index="name" :ellipsis="true" align="center"></a-table-column>-->
<!--            <a-table-column title="标签图标" data-index="imgUrl" :ellipsis="true" align="center">-->
<!--              <template slot-scope="text">-->
<!--                <ZoomMedia style="height: 20px;" :value="text"></ZoomMedia>-->
<!--              </template>-->
<!--            </a-table-column>-->
<!--            <a-table-column title="分类" data-index="categoryName" :ellipsis="true" align="center"></a-table-column>-->
<!--            <a-table-column title="品牌" data-index="" :ellipsis="true" align="center"></a-table-column>-->
<!--            <a-table-column title="型号" data-index="specStr" :ellipsis="true" align="center"></a-table-column>-->
<!--            <a-table-column title="选择政策" data-index="" :ellipsis="true" align="center">-->
<!--              <template slot-scope="text, row">-->
<!--                <a-select placeholder="请选择" style="width: 100%" v-model="row.policyInfoVOS.id" @change="onPolicySelectChange">-->
<!--                  <a-select-option :value="item.id" v-for="(item, index) in row.policyInfoVOS" :key="index">{{item.name}}</a-select-option>-->
<!--                </a-select>-->
<!--              </template>-->
<!--            </a-table-column>-->
<!--            <a-table-column title="单位" data-index="" :ellipsis="true" align="center"></a-table-column>-->
<!--          </a-table>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="steps-action">-->
<!--        <a-button v-if="current > 0" style="margin-left: 8px" @click="prev">上一步</a-button>-->
<!--        <a-button v-if="current < steps.length - 1" type="primary" @click="next">下一步</a-button>-->
<!--        <a-button v-if="current === steps.length - 1" type="primary" @click="onConfirm">确定</a-button>-->
<!--      </div>-->
      <div class="realtor">
        <a-form-model ref="searchData" :label-col="{xs:6}" :wrapper-col="{xs:18}">
          <a-row :gutter="8">
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="商品名称">
                <a-input v-model="searchData.name" placeholder="请输入商品名称"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="商品分类">
                <a-input v-model="searchData.name" placeholder="请输入商品名称"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
                <a-button type="primary" @click="getList(0)" icon="search">查询</a-button>
                <a-button type="default" @click="getList(1)" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <a-table :data-source="tableData" :columns="columns" :loading="tableLoading" :rowKey="record => record.id" :pagination="page" bordered @change="changeTable" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
            <span slot="imgUrl" slot-scope="item">
              <ZoomMedia style="height: 20px;" :value="item"></ZoomMedia>
            </span>
        </a-table>
      </div>
      <div class="footer-btns">
        <a-button @click="onCancel">取消</a-button>
        <a-button type="primary" :loading="btnsLoading" @click="onSubmit">确定</a-button>
      </div>
<!--      <ConfirmProduct ref="ConfirmProduct" :dataList="dataList" :dealerId="dealerId"></ConfirmProduct>-->
    </a-modal>
  </div>
</template>

<script>
  import ConfirmProduct from './ConfirmProduct.vue'
  export default {
    components: {
      ConfirmProduct
    },
  data() {
    return {
      visible: false,
      tableLoading: false,
      btnsLoading: false,
      tableData: [],
      handle: '',
      searchData: {},
      columns: [
        {
          title: '商品名称',
          dataIndex: 'name',
          // width: 200,
          ellipsis:true,
          align: 'center'
        },
        {
          title: '标签图标',
          dataIndex: '',
          // width: 120,
          ellipsis:true,
          align: 'center'
        },
        {
          title: '分类',
          dataIndex: 'categoryName',
          // width: 120,
          ellipsis:true,
          align: 'center'
        },
        {
          title: '品牌',
          dataIndex: 'brandName',
          // width: 120,
          ellipsis:true,
          align: 'center'
        },
        {
          title: '建议零售价',
          dataIndex: 'price',
          // width: 120,
          ellipsis:true,
          align: 'center'
        },

        {
          title: '销售价',
          dataIndex: 'sellPrice',
          // width: 120,
          ellipsis:true,
          align: 'center'
        },
        {
          title: '单位',
          dataIndex: '',
          // width: 120,
          ellipsis:true,
          align: 'center'
        },
      ],
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0
      },
      selectedRowKeys: [],
      selectedRows: [],
      dealerId: '',
      dataList: [],
      current: 0,
      steps: [
        {
          title: '第一步',
          content: '请选择商品',
        },
        {
          title: '第二步',
          content: '请选择政策',
        },
      ],
      policyList: [],
      policyKeysList: [],
      list: []
    }
  },
  created() {},
  methods: {
    // 打开弹框是触发
    isShow(id, list) {
      this.visible = true
      this.dealerId = id
      this.list = list
      this.getList(0)
    },
    // 获取数据源
    getList(num) {
      this.tableLoading = true
      if(num === 1) {
        this.searchData = {}
      }
      this.page.productStatus = 3
      this.axios.get('/api/product/system/goods/list',{params: this.page} ).then(res => {
        if(res.code === 200) {
          this.tableData = res.body.records
          this.tableLoading = false
        }
      })
    },
    // 关闭弹框
    onCancel() {
      this.visible = false
    },
    changeTable(pagination) {
      this.page = pagination
      this.page.pageNumber = pagination.current
      this.getList(1)
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    onSelectedChange(selectedRowKeys, selectedRows) {
      this.policyKeysList = selectedRowKeys
      this.policyList = selectedRows
    },
    // 监听当前选中数据
    onPolicySelectChange(val, data) {
      console.log('val----', val)
      console.log('data----', data)
    },
    // 步骤条下一步
    next() {
      this.onSubmit()
    },
    // 步骤条上一步
    prev() {
      this.current--;
    },
    onConfirm() {
      
    },
    // 获取政策列表
    onSubmit() {
      let ids = []
      if(this.list.length > 0) {
        let item = this.list.map(e => e.goodsId)
        ids = this.selectedRowKeys.filter(i => !item.some(e => e === i))
      }
      let tmpIds = ids.concat(this.selectedRowKeys)
      let res = new Map()
      ids = tmpIds.filter(e => !res.has(e) && res.set(e, 1))
      let data = {
        dealerId: this.dealerId,
        goodsIds: ids
      }
      if(this.selectedRowKeys.length <= 0) {
        return this.$message.warning('请至少选择一条数据')
      }
      // if(data.goodsIds.length <= 0) {
      //   return this.$message.warning('当前商品已添加')
      // }
      this.btnsLoading = true
      this.axios.post(`/api/order/order/orderInfo/queryProListAndPolicy`, data).then(res => {
        if(res.code === 200) {
          this.dataList = res.body
          this.visible = false
          this.$emit('get-product-list', this.dataList)
          this.btnsLoading = false
        }
      })
      this.selectedRowKeys = []
    },
  }
 }
</script>

<style scoped>
  .steps-action {
    margin-top: 20px;
    text-align: right;
  }
  .steps-content {
    margin-top: 60px;
  }
  .footer-btns {
    text-align: right;
    border-top: 1px solid #e8e8e8;
    padding-top: 20px;
    margin-top: 20px;
  }
</style>